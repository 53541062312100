<script>
import simplebar from 'simplebar-vue'
import i18n from '../i18n'
import service from '@/server/http_service'
import { layoutComputed } from '@/state/helpers'
import Swal from 'sweetalert2'
import moment from 'moment'
import global_func from '@/global_function'
// import main from '@/main'

export default {
  components: { simplebar },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  data() {
    var lang = 'th'
    var user = JSON.parse(localStorage.getItem('users'))
    if (localStorage.getItem('account_lang')) {
      lang = localStorage.getItem('account_lang')
    } else {
      localStorage.setItem('account_lang', 'th')
    }
    var flags
    if (lang == 'en') {
      flags = require('@/assets/images/flags/us.jpg')
    } else {
      flags = require('@/assets/images/flags/thailand.jpg')
    }

    moment.locale(lang)

    var lang_obj = i18n.t('top_topbar_menu')
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/thailand.jpg'),
          language: 'th',
          title: 'ภาษาไทย',
        },
        {
          flag: require('@/assets/images/flags/us.jpg'),
          language: 'en',
          title: 'English',
        },
      ],
      lang : lang_obj,
      current_flags: flags,
      current_language: lang,
      profile_name: user[0].name,
      personal_data: user[0],
      file_raw_data: [],
      profile: [],
      profile_pic_flg: false,
      noti_count: 0,
      toast_id: 'bh_toast_',
      noti_array: [],
      connectWS: {},
      noti_bell_data : [],
      noti_bell_loading_flag : true,
      noti_bell_dot_flag : false,
    }
  },
  computed: {
    ...layoutComputed,
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
          case 'dark':
            document.body.setAttribute('data-topbar', 'dark')
            break
          case 'light':
            document.body.setAttribute('data-topbar', 'light')
            document.body.removeAttribute('data-layout-size', 'boxed')
            break
          case 'colored':
            document.body.setAttribute('data-topbar', 'colored')
            document.body.removeAttribute('data-layout-size', 'boxed')
            break
          default:
            document.body.setAttribute('data-topbar', 'dark')
            break
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
          case 'boxed':
            document.body.setAttribute('data-layout-size', 'boxed')
            break
          case 'fluid':
            document.body.setAttribute('data-layout-mode', 'fluid')
            document.body.removeAttribute('data-layout-size')
            break
          default:
            document.body.setAttribute('data-layout-mode', 'fluid')
            break
          }
        }
      },
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
    this.getPicProfile()
    this.connectWebSocket()
    // console.log(localStorage.getItem('logout_redirect_path'))

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId == 'noti_bell') {
        this.getAllNotification()
      }
    })
  },
  methods: {
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable')
      if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    toggleMenu() {
      let element = document.getElementById('topnav-menu-content')
      element.classList.toggle('show')
    },
    setLanguage(locale) {
      // console.log(locale)
      i18n.locale = locale
      this.current_language = i18n.locale
      if (locale == 'en') {
        this.current_flags = require('@/assets/images/flags/us.jpg')
      } else {
        this.current_flags = require('@/assets/images/flags/thailand.jpg')
      }
      localStorage.setItem('account_lang', locale)
      location.reload()
    },
    getPicProfile() {
      var that = this
      service
        .postService('getFileDataById', {
          id_member: that.personal_data.id_member,
        })
        .then((rp) => {
          // console.log('ไฟล์')
          // console.log(rp)
          this.file_raw_data = rp.file_data
          this.setPicProfile()
        })
    },
    setPicProfile() {
      var data = this.file_raw_data
      var pic_obj = []
      this.profile = []
      this.profile_pic_flg = false
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        if (element.title == 'profile') {
          var path = element.blob
          // var path = require('/../file_upload_dtc_backhaul/images/profile/' + element.filename)
          // var path = require('../../file_upload_dtc_backhaul/images/profile/' + element.filename)
          // var path = require('/var/vue/file_upload_dtc_backhaul/images/profile/' + element.filename)
          // console.log(path)
          pic_obj.push(path)
          this.profile_pic_flg = true
        }
      }
      this.profile = pic_obj
    },
    logoutFunc() {
      Swal.fire({
        title: 'คุณต้องการออกจากระบบ ใช่ หรือ ไม่',
        text: '',
        icon: 'warning',
        confirmButtonText: '  ใช่  ',
        cancelButtonText: '  ไม่  ',
        allowOutsideClick: true,
        showCancelButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/logout'
        } else if (result.isDenied) {
          return
        }
      })
    },
    connectWebSocket() {
      let that = this
      let encode = global_func.encode(JSON.stringify(this.personal_data.id_member), 'BH_websocket')
      // console.log(encode)
      // n4u526n583o5b4e5u5e4d4g5a4o4e4 = 'noti?id_member='
      // this.connectWS = new WebSocket(`ws://localhost:8091/n4u526n583o5b4e5u5e4d4g5a4o4e4${encode}`)
      this.connectWS = new WebSocket(`ws://backhaul.dtc.co.th:8091/n4u526n583o5b4e5u5e4d4g5a4o4e4${encode}`)
      var connectWS = this.connectWS
      connectWS.onopen = function () {
        // จะทำงานเมื่อเชื่อมต่อสำเร็จ
        // console.log('connect webSocket')
        connectWS.send('Backhaul client connected') // ส่ง Data ไปที่ Server
      }
      connectWS.onerror = function (error) {
        console.error('WebSocket Error ' + error)
        setTimeout(() => {
          that.connectWebSocket()
        }, 10000)
      }
      connectWS.onmessage = function (e) {
        // log ค่าที่ถูกส่งมาจาก server
        // console.log('message from server: ', e.data)
        that.toast(e.data)
      }
    },
    toast(noti_data) {
      // console.log(noti_data)
      let that = this
      var data = []
      if (noti_data != '' && noti_data != null && noti_data != undefined) {
        data = JSON.parse(noti_data)
        data.noti_data = JSON.parse(data.noti_data)
      }
      // console.log(data)

      if(data.noti_data != null && data.noti_data != undefined && data.noti_data != '') {

        // เช็ค user id
        let id_member = that.personal_data.id_member
        let push_to = data.noti_data.push_to
        if(id_member == push_to) {
          // แปลงรูปภาพ
          var pic_obj = []
          var status_pic_data = []
          var status_pic_flg = false
          for (let index = 0; index < data.noti_pic_data.length; index++) {
            let data_row = data.noti_pic_data[index]
            let element = data_row
            if (element.title == 'profile') {
              var path = element.blob
              pic_obj.push(path)
              status_pic_flg = true
            }
          }
          status_pic_data = pic_obj

          // เช็ต สถานะ noti
          var status_id = data.noti_data.push_status
          var status_class_name = this.getNotiStatusColor(status_id)
          var status_name = this.getNotiStatus(status_id)

          // สร้างข้อความ noti
          var by_name = data.noti_push_by.mover_name
          var plan_id = data.noti_data.push_detail.id_tracking

          let id = {
            noti_id: this.toast_id + this.noti_count,
            noti_status: status_id,
            noti_status_class: status_class_name,
            noti_status_name: status_name,
            noti_pic_data: status_pic_data[0],
            noti_pic_flag: status_pic_flg,
            noti_plan_id: plan_id,
            noti_by_name: by_name,
          }
          this.noti_array.push(id)
          // console.log(id)
          let toast_id = 'bh_toast_' + this.noti_count
          setTimeout(() => {
            that.$bvToast.show(toast_id)
          }, 500)
          this.noti_count++
        }
      }

    },
    closeToast(toast_id) {
      this.$bvToast.hide(toast_id)
    },
    getNotiStatus(status_id) {
      let status_name = ''
      if (status_id == '1') {
        status_name = this.lang.noti.job_offer
      } else if (status_id == '2') {
        status_name = this.lang.noti.new_offer
      } else if (status_id == '3') {
        status_name = this.lang.noti.have_offer
      } else if (status_id == '4') {
        status_name = this.lang.noti.answer
      } else if (status_id == '5') {
        status_name = this.lang.noti.in_progress
      } else if (status_id == '6') {
        status_name = this.lang.noti.succeed
      } else if (status_id == '7') {
        status_name = this.lang.noti.cancel
      } else if (status_id == '8') {
        status_name = this.lang.noti.expire
      } else {
        status_name = this.lang.noti.noti
      }
      return status_name
    },
    getNotiStatusColor(status_id) {
      let status_color = ''
      if (status_id == '1') {
        status_color = 'status_1'
      } else if (status_id == '2') {
        status_color = 'status_1'
      } else if (status_id == '3') {
        status_color = 'status_1'
      } else if (status_id == '4') {
        status_color = 'status_1'
      } else if (status_id == '5') {
        status_color = 'status_1'
      } else if (status_id == '6') {
        status_color = 'status_2'
      } else if (status_id == '7') {
        status_color = 'status_3'
      } else if (status_id == '8') {
        status_color = 'status_8'
      } else {
        status_color = 'status_1'
      }
      return status_color
    },
    // eslint-disable-next-line no-unused-vars
    getAllNotification() {
      // this.noti_bell_loading_flag = true
      // console.log(flag_noti_bell)
      // if(flag_noti_bell) {
      //   this.noti_bell_dot_flag = true
      // } else {
      //   this.noti_bell_dot_flag = false
      // }
      var that = this
      service
        .postService('getAllNotificationById', {
          id_member: that.personal_data.id_member,
        })
        .then((rp) => {
          // console.log(rp)
          if(rp.data != undefined) {
            this.createNotiBell(rp.data)
          }
        })
    },
    createNotiBell(all_noti_data) {
      let noti_data = all_noti_data
      // console.log(noti_data)
      this.noti_bell_data = []
      let data_array = []
      for (let index = 0; index < noti_data.length; index++) {
        let row = noti_data[index]

        if (row != '' && row != null && row != undefined) {
          row.noti_data = JSON.parse(row.noti_data)
          // แปลงรูปภาพ
          var pic_obj = []
          var status_pic_data = []
          var status_pic_flg = false
          for (let index2 = 0; index2 < row.noti_pic_data.length; index2++) {
            let data_row = row.noti_pic_data[index2]
            let element = data_row
            if (element.title == 'profile') {
              var path = element.blob
              pic_obj.push(path)
              status_pic_flg = true
            }
          }
          status_pic_data = pic_obj

          // เช็ต สถานะ noti
          var status_id = row.noti_data.push_status
          var status_class_name = this.getNotiStatusColor(status_id)
          var status_name = this.getNotiStatus(status_id)

          // สร้างข้อความ noti
          var by_name = row.noti_push_by.mover_name
          var plan_id = row.noti_data.push_detail.id_tracking
        
          let data = {
            noti_id: this.toast_id + this.noti_count,
            noti_status: status_id,
            noti_status_class: status_class_name,
            noti_status_name: status_name,
            noti_pic_data: status_pic_data[0],
            noti_pic_flag: status_pic_flg,
            noti_plan_id: plan_id,
            noti_by_name: by_name,
            noti_time_fromnow : moment(row.noti_action_time).fromNow(),
          }
          data_array.push(data)
        }
      }

      this.noti_bell_data = data_array
      this.noti_bell_loading_flag = false

    },
    async actionNoti(noti_data) {
      let status = noti_data.noti_status
      let id_tracking = noti_data.noti_plan_id
      let mode_user = await global_func.findString(id_tracking, 'BH')
      let link = ''

      if(mode_user) {
        link = 'transportation_plan'
      } else {
        link = 'carrier'
      }

      if(status == '5' || status == '6') {
        link += '/tracking'
      } else {
        link += '/plans_display'
      }

      let location = window.location
      let check_url = await global_func.findString(location.pathname, link)
      if(check_url) {
        window.location = `/${link}/?data=${id_tracking}`
      } else {
        this.$router.push({ path: `/${link}/?data=${id_tracking}` })
      }
    },
  },
}
</script>

<template>
  <div>
    <header id="page-topbar">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box" style="padding: 0em 0em 0em 1.5em">
            <a href="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm-dark.png" alt height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt height="20" />
              </span>
            </a>

            <a href="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/dtc_backhaul_logo_topbar_light.png" alt height="60" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/dtc_backhaul_logo_topbar_light.png" alt height="60" />
              </span>
            </a>
          </div>

          <!-- <button type="button" class="btn btn-sm px-3 font-size-24 d-lg-none header-item" data-toggle="collapse" @click="toggleMenu">
          <i class="ri-menu-2-line align-middle" />
        </button> -->

          <div class="dropdown d-none d-lg-inline-block ml-1">
            <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen" @click="initFullScreen">
              <i class="ri-fullscreen-line" />
            </button>
          </div>
          <!-- App Search-->
          <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />
            <span class="ri-search-line" />
          </div>
        </form> -->

          <!-- <b-dropdown variant="black" class="dropdown-mega d-none d-lg-block ml-2" toggle-class="header-item" menu-class="dropdown-megamenu">
          <template v-slot:button-content>
            {{ $t('navbar.dropdown.megamenu.text') }}
            <i class="mdi mdi-chevron-down" />
          </template>
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.uicontent.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.lightbox') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rating') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.forms') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.tables') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.charts') }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.application.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.ecommerce') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.calendar') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.email') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.projects') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.tasks') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.application.list.contacts') }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.extrapages.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.lightsidebar') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.compactsidebar') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.horizontallayout') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.maintenance') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.comingsoon') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.timeline') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.extrapages.list.faqs') }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ $t('navbar.dropdown.megamenu.uicontent.title') }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.lightbox') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.rating') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.forms') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.tables') }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ $t('navbar.dropdown.megamenu.uicontent.list.charts') }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="@/assets/images/megamenu-img.png" alt class="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-dropdown> -->
        </div>

        <div class="d-flex">
          <div class="dropdown d-inline-block d-lg-none ml-2">
            <button id="page-header-search-dropdown" type="button" class="btn header-item noti-icon waves-effect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ri-search-line" />
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-search-dropdown">
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search ..." />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="submit">
                        <i class="ri-search-line" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
            <template v-slot:button-content>
              <img v-if="profile_pic_flg" class="rounded-circle header-profile-user" :src="profile" alt="Header Avatar" />
              <img v-else class="rounded-circle header-profile-user" src="@/assets/images/profile/Group 3361.png" alt="Header Avatar" />
              <span class="d-none d-xl-inline-block ml-1">{{ profile_name }}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block" />
            </template>
            <!-- item-->
            <a class="dropdown-item" href="/profile/personal_profile">
              <i class="ri-user-line align-middle mr-1" />
              {{ $t('navbar.dropdown.kevin.list.profile') }}
            </a>
            <a class="dropdown-item" href="/profile/truck">
              <i class="ri-truck-line align-middle mr-1" />
              {{ $t('navbar.dropdown.kevin.list.vehicle') }}
            </a>
            <a class="dropdown-item" href="/profile/settings">
              <i class="ri-settings-5-line align-middle mr-1" />
              {{ $t('navbar.dropdown.kevin.list.settings') }}
            </a>
            <div class="dropdown-divider" />
            <a class="dropdown-item text-danger" style="cursor: pointer" @click="logoutFunc()">
              <i class="ri-shut-down-line align-middle mr-1 text-danger" />
              {{ $t('navbar.dropdown.kevin.list.logout') }}
            </a>
          </b-dropdown>

          <b-dropdown id="noti_bell" ref="noti_bell" right menu-class="dropdown-menu-lg p-0" toggle-class="header-item noti-icon" variant="black">
            <template v-slot:button-content>
              <div>
                <i class="ri-notification-3-line" />
                <span v-show="noti_bell_dot_flag" class="noti-dot" />
              </div>
            </template>
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <span class="font-size-16 font-weight-bold">
                    {{ $t('navbar.dropdown.notification.text') }}
                  </span>
                </div>
                <div class="col-auto">
                  <a href="/notification/all_notification" class="small font-size-12">{{ $t('navbar.dropdown.notification.subtext') }}</a>
                </div>
              </div>
            </div>
            <simplebar style="max-height: 260px">
              <div v-if="noti_bell_loading_flag" class="d-flex justify-content-center">
                <b-spinner class="m-2" variant="primary" role="status" />
              </div>
              <div v-else-if="noti_bell_data.length > 0">
                <div v-for="(option, index) in noti_bell_data" :key="index">
                  <a class="text-reset notification-item" @click="actionNoti(option)">
                    <div class="media noti_bell_text">
                      <img v-if="option.noti_pic_flag" :src="option.noti_pic_data" class="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                      <img v-else src="@/assets/images/profile/Group 3361.png" class="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                      <div class="media-body">
                        <div class="font-weight-medium">
                          <span :class="option.noti_status_class"> [{{ option.noti_status_name }}] </span>
                          <span> {{ lang.noti.plan }} {{ option.noti_plan_id }} </span>
                        </div>
                        <div class="font-size-12 text-muted">
                          <p class="mb-1">
                            {{ option.noti_by_name }}
                          </p>
                          <p class="mb-0">
                            <i class="mdi mdi-clock-outline" />
                            {{ option.noti_time_fromnow }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div v-else class="d-flex justify-content-center">
                <span class="font-size-14 text-muted" style="padding : 10px">
                  {{ $t('navbar.dropdown.notification.nodata') }}
                </span>
              </div>
            </simplebar>
            <!-- <div class="p-2 border-top">
              <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
                <i class="mdi mdi-arrow-right-circle mr-1" />
                {{ $t('navbar.dropdown.notification.button') }}
              </a>
            </div> -->
          </b-dropdown>

          <!-- <b-dropdown variant="white" right toggle-class="header-item">
            <template v-slot:button-content>
              <img class :src="current_flags" alt="Header Language" height="16" />
            </template>
            <b-dropdown-item v-for="(entry, i) in languages" :key="`Lang${i}`" class="notify-item" :value="entry" :link-class="{ active: entry.language === current_language }" @click="setLanguage(entry.language)">
              <img :src="`${entry.flag}`" alt="user-image" class="mr-1" height="12" />
              <span class="align-middle">{{ entry.title }}</span>
            </b-dropdown-item>
          </b-dropdown> -->
          <!-- <b-button @click="toast()">Show toast</b-button> -->

          <!-- <b-dropdown class="d-none d-lg-inline-block d-lg-inline-block ml-1" menu-class="dropdown-menu-lg" right toggle-class="header-item noti-icon" variant="black">
            <template v-slot:button-content>
              <i class="ri-apps-2-line" />
            </template>
            <div class="px-lg-2">
              <div class="row no-gutters">
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="@/assets/images/brands/github.png" alt="Github" />
                    <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="@/assets/images/brands/bitbucket.png" alt="bitbucket" />
                    <span>{{ $t('navbar.dropdown.site.list.github') }}</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="@/assets/images/brands/dribbble.png" alt="dribbble" />
                    <span>{{ $t('navbar.dropdown.site.list.dribbble') }}</span>
                  </a>
                </div>
              </div>

              <div class="row no-gutters">
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />
                    <span>{{ $t('navbar.dropdown.site.list.dropbox') }}</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="@/assets/images/brands/mail_chimp.png" alt="mail_chimp" />
                    <span>{{ $t('navbar.dropdown.site.list.mailchimp') }}</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="javascript: void(0);">
                    <img src="@/assets/images/brands/slack.png" alt="slack" />
                    <span>{{ $t('navbar.dropdown.site.list.slack') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </b-dropdown> -->

          <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect toggle-right" @click="toggleRightSidebar">
              <i class="ri-menu-line toggle-right" />
            </button>
          </div>
        </div>
      </div>
    </header>
    <div v-for="(option, index) in noti_array" :key="index">
      <b-toast :id="option.noti_id" toaster="b-toaster-bottom-left" toast-class="width_400" header-class="d-none" solid>
        <template #default>
          <div class="position-absolute noti_close_btn">
            <span class="fas fa-times" @click="closeToast(option.noti_id)" />
          </div>
          <div class="row">
            <div class="col-3 noti_pic">
              <img v-if="option.noti_pic_flag" class="rounded-circle" :src="option.noti_pic_data" alt="Header Avatar" />
              <img v-else class="rounded-circle" src="@/assets/images/profile/Group 3361.png" alt="Header Avatar" />
            </div>
            <div class="col-8 noti_body_text">
              <span :class="option.noti_status_class"> [{{ option.noti_status_name }}] </span>
              <br />
              <span> {{ lang.noti.plan }} {{ option.noti_plan_id }} </span>
              <br />
              <span class="font-size-12">
                {{ option.noti_by_name }}
              </span>
            </div>
          </div>
        </template>
      </b-toast>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.position-absolute.noti_close_btn {
  right: 20px;
  font-size: 20px;
  color: #808080;
}
:hover.noti_close_btn {
  cursor: pointer;
  color: #505d69;
}
.noti_body_text {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noti_body_text .status_1 {
  color: #03aeef;
}
.noti_body_text .status_2 {
  color: #33c38f;
}
.noti_body_text .status_3 {
  color: #ff5d5d;
}
.noti_body_text .status_4 {
  color: #faab00;
}
.noti_pic {
  padding-right: 0;
}
.noti_pic img {
  width: 100%;
}
.toast.width_400 {
  max-width: 400px;
  width: 400px;
  z-index: 99999;
}
.status_1 {
  color: #03aeef;
}
.status_2 {
  color: #33c38f;
}
.status_3 {
  color: #ff5d5d;
}
.status_4 {
  color: #faab00;
}
.noti_bell_text .media-body{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification-item:hover {
  cursor: pointer;
}
</style>
